<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Admin',
  computed: {
    token() {
      return this.$store.state.token;
    },
    authChecked() {
      return this.$store.state.authChecked;
    },
  },
  watch: {
    authChecked() {
      this.redirectOnNoAuth();
    },
  },
  methods: {
    redirectOnNoAuth() {
      if (this.token === '') {
        this.$router.push('/');
      }
    },
  },
};
</script>
